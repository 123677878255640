.content_style ul li {
  padding: 4px 0 4px 20px;
  color: #444;
  list-style: none;
  background: url("../../../images/list_style.jpg") no-repeat top 13px left;
}
/*table {
  margin: 0;
  padding: 0;

  table-layout: fixed;

  border: none;
  border-collapse: collapse;
}

tr {
  &:nth-child(odd) {
    td {
      background-color: @grey-8;
    }
  }

  &:nth-child(even) {
    background-color: @grey-6;
  }
}

th {
  padding: 15px;

  color: @white;

  background-color: @orange;

  border: none;
}

td {
  padding: 10px 25px;

  font-size: 14px;

  @media screen and (min-width: 801px) {
    border-bottom: none;
  }
}*/
/*.content_style {
  padding-top: 30px;
  padding-bottom: 30px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 15px;

    &.icon {
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        color: @grey-5;
      }
    }
  }

  p {
    margin-bottom: 15px;
  }

  ul {
    margin-left: 20px;

    ul {
      margin-top: 5px;

      li {
        background: url("/images/svg/arrow-right-double.svg") no-repeat top 5px
          left;
        background-size: 18px 18px;
      }
    }

    li {
      padding: 4px 0 4px 24px;

      list-style: none;

      background: url("/images/svg/arrow-right.svg") no-repeat top 5px left;
      background-size: 18px 18px;
    }
  }

  ol {
    margin-left: 30px;

    counter-reset: item;

    li {
      padding: 4px 0 4px 10px;

      text-indent: -20px;

      list-style-position: inside;
      list-style-type: none;

      counter-increment: item;

      &:before {
        content: counter(item) ".";
        display: inline-block;
        width: 1em;
        padding-right: 20px;

        font-weight: bold;
        text-align: right;
      }
    }
  }

  */
/*# sourceMappingURL=css/content-style.css.map */